.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    background: #ffffff;
    border-radius: 1rem;
    width: max-content;
}
  
.pagination a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.1rem;
    font-size: 1.2rem;
    margin: 0.7rem;
    color: #293241;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background: none;
}

.pagination a:hover {
    color: #23adad;
}

.pagination .page-item.active .page-link {
    color: white;
    background-color: #23adad;
    border: none;
}

.pagination .page-item-last .page-link {
    color: #23adad;
    border: none;
}

.pagination .page-item-first .page-link {
    color: #23adad;
    border: none;
}