.county-text-start {
    margin-bottom: 5px;
    color: #e0fbfc;
    text-align: left;
}

.county-text-head {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    height: 75px;
}

.county-card {
    width: 20rem; 
    background: #3d5a80; 
    border: 3px solid #293241;
    transition: 180ms transform ease;
    opacity: 0;
    animation: none;
}

.county-card:hover {
    transform: scale(1.03);
}

.county-learn-more {
    background-color: #23adad; 
    color: #FFFFFF;
    border: none;
    transition: 180ms transform ease;
}

.county-learn-more:hover {
    transform: scale(1.05);
    background-color: #23adad;
}

.county-card.visible {
    opacity: 1;
    animation: appearAnimation 0.8s ease;
}

@keyframes appearAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}