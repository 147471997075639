.about-text-start {
    margin-bottom: 5px;
    color: #e0fbfc;
    text-align: left;
}

.about-text-head {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.about-card {
    width: 22.5rem; 
    background: #3d5a80; 
    border: 3px solid #293241;
    opacity: 0;
    transition: 180ms transform ease;
    animation: none;
}

.about-card:hover {
    transform: scale(1.02);
}

.about-card.visible {
    opacity: 1;
    animation: appearAnimation 0.8s ease;
}

.about-button{
    background-color: #23adad; 
    color: #FFFFFF;
    border: none;
    transition: 180ms transform ease;
    margin-top: 10px;
}

.about-button:hover {
    transform: scale(1.05);
    background-color: #23adad;
}

.tool-card {
    background-color: #3d5a80;
    border: 3px solid #293241;
    width: 10rem;
    opacity: 0;
    transition: 180ms transform ease;
    animation: none;
}

.tool-card.visible {
    opacity: 1;
    animation: appearAnimation 0.8s ease;
}

.tool-card:hover {
    transform: scale(1.03);
}

@keyframes appearAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}