.header {
    color: white;
    transition: color 0.1s ease;
    text-decoration: none;
}

.header-icon {
    color: white;
    text-decoration: none;
}

.header-icon:hover {
    color: white;
}

.header:hover {
    color: #23adad;
}

.header.active {
    color: white;
    margin: 2rem auto;
    background: #23adad;
    border-radius: 5px;
    border: 3px solid #23adad;
}