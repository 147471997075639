.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  height: 100%;
  background: rgb(205,233,237);
  background: radial-gradient(circle, #c2e9fb 0%, #c2e9fb 10%, #a1c4fd 100% );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@supports (scrollbar-color: #e0fbfc #282c34) {
  * {
    scrollbar-color: #e0fbfc #282c34;
    scrollbar-width: thin;
  }
}
