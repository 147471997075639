.input-box-nav {
    position: relative;
    align-self: right;
}

.input-box-model {
    position: relative;
    padding-top: 25px;
    align-items: center;
}

.search-input {
    width: 400px;
    padding: 6px 35px;
    border-radius: 12px;
    font-size: 16px;
    background-color: white;
    color: #23adad;
    box-sizing: border-box;
}
  
.search-input:focus {
    outline: none;
    border-color: #23adad;
    color: #00B4CC;
}

.search-input::placeholder {
    color: #98c1d9;
}

#search-all-icon {
    position: relative;
    top: 50%;
    left: 27px;
    transform: translateY(-10%);
    cursor: pointer;
    opacity: 0.75;
    color: #23adad
}

#search-counties-icon {
    position: relative;
    left: 27px;
    transform: translateY(-10%);
    cursor: pointer;
    opacity: 0.75;
    color: #23adad
}

#search-programs-icon {
    position: relative;
    left: 27px;
    transform: translateY(-10%);
    cursor: pointer;
    opacity: 0.75;
    color: #23adad
}

#search-libraries-icon {
    position: relative;
    left: 27px;
    transform: translateY(-10%);
    cursor: pointer;
    opacity: 0.75;
    color: #23adad
}