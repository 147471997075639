.about-header {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1244px) {
  .about-header{
    flex-direction: column;
  }
}

.video {
  width: 900px;
  max-width: 90%;
  aspect-ratio: 800 / 560;
  transition: 180ms transform ease;
  animation: 0.8s appearAnimation ease;
}

.video:hover {
  transform: scale(1.03);
}

.home-card {
  background-color: #293241;
  color: white;
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-top: 2%;
  margin: 50px;
  transition: 180ms transform ease;
  animation: 0.8s appearAnimation ease;
}

.home-card:hover {
    transform: scale(1.03);
}

@keyframes appearAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}